import $ from "jquery";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Swiper from "swiper";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

$(document).ready(function () {
  gsap.to(".hero__slogan", {
    opacity: 1,
    y: 0,
    duration: 1,
  });

  $(".hero__scroll").click(() => {
    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: ".about",
      },
    });
  });

  new Swiper(".about__links", {
    slidesPerView: "auto",
    spaceBetween: 0,
    breakpoints: {
      992: {
        slidesPerView: 4,
      },
    },
  });

  new Swiper(".products-carousel", {
    slidesPerView: "auto",
  });

  ScrollTrigger.create({
    trigger: ".outro",
    start: "top 50%",
    onEnter: () => {
      $(".outro").addClass("outro--entered");
    },
    once: true,
  });
});

jQuery(document).ready(function ($) {
  $(window).scroll(function () {
    if (
      $(window).scrollTop() + $(".footer").outerHeight() >
      $(document).height() - $(window).height()
    ) {
      $(".espa-container").hide(1000);
    } else {
      $(".espa-container").show(1000);
    }
  });
});
